<!--
 * @Author: diaowangtao
 * @Date: 2020-08-18 14:07:29
 * @Description: 主页标题
-->

<template>
  <div :title="title" class="title">
    <p>{{title|sLang}}</p>
    <i></i>
  </div>
</template>

<script>
export default {
  name: 'myTitle',
  props: {
    title: '',
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
}
</script>

<style lang='scss'>
.title {
  font-size: 28px;
  color: #232d44;
  text-align: center;
  font-weight: bold;
  i {
    display: block;
    width: 240px;
    height: 3px;
    background-image: linear-gradient(
      90deg,
      #ffffff 0%,
      #f9ce9b 50%,
      #ffffff 100%
    );
    margin: 20px auto 0 auto;
  }
}
</style>