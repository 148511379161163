<!--
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:45:45
 * @Description: 首页
-->
<template>
  <div class="index">
    <div v-if="banners[0].url">
      <my-banner
        :bg_banner="banners[0].url"
        :link="banners[0].link"
        @goToLink="goToLink"
        class="hand"
        v-if="banners.length<2"
      ></my-banner>
      <my-swiper :banners="banners" @goToLink="goToLink" v-if="banners.length>1" class="hand"></my-swiper>
    </div>
    <div v-if="article.bg" id="anchor_0">
      <my-title :title="title_1" class="title_s1" ></my-title>
      <div class="news">
        <img :src="article.bg" alt class="left" />
        <div class="right news_right">
          <p v-html="$options.filters.sLang('公司简介')"></p>
          <div class="content">{{article.digest|sLang}}</div>
          <my-button :text="button_1" @goToPage="goToCompany" class="news_button"></my-button>
        </div>
      </div>
    </div>

    <div class="investment" v-if="investmentList" id="anchor_1">
      <my-title :title="title_2" class="title_s2"></my-title>
      <div class="w1200">
        <img :src="require('@/assets/img/index_slider.png')" alt class="left" />
        <div class="right">
          <div
            class="investment_list"
            v-for="(item,index) in investmentList"
            :key="index"
            @click="goToNews(item.id)"
            :id="item.id"
            htmdEvt="click_news"
          >
            <div class="left hand">
              <p>{{item.time.slice(0,7)|sLang}}</p>
              <span>{{item.time.slice(8)|sLang}}</span>
            </div>
            <div class="right">
              <p class="investment_title hand">{{item.title|sLang}}</p>
              <div>
                <p class="hand">{{item.digest|sLang}}</p>
                <span class="hand" v-html="$options.filters.sLang('详情')"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w1200">
        <my-button :text="button_2" @goToPage="goToInvest" class="investment_button"></my-button>
      </div>
    </div>

    <div class="newsList" v-if="newsList" id="anchor_2">
      <my-title :title="title_3" class="title_s2"></my-title>
      <div class="w1200">
        <img :src="require('@/assets/img/news.jpg')" alt class="left" />
        <div class="right">
          <div
            class="investment_list" 
            v-for="(item,index) in newsList"
            :key="index"
            @click="goToNews(item.id)"
            :id="item.id"
            htmdEvt="click_news"
          >
            <div class="left hand">
              <p>{{item.time.slice(0,7)|sLang}}</p>
              <span>{{item.time.slice(8)|sLang}}</span>
            </div>
            <div class="right">
              <p class="investment_title hand">{{item.title|sLang}}</p>
              <div>
                <p class="hand">{{item.digest|sLang}}</p>
                <span class="hand" v-html="$options.filters.sLang('详情')"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w1200">
        <my-button :text="button_2" @goToPage="goToNewsCenter" class="investment_button"></my-button>
      </div>
    </div>
  </div>
</template>

<script>
import myBanner from '@/components/myBanner/index.vue'
import mySwiper from '@/components/mySwiper/index.vue'
import myTitle from '@/components/myTitle/index.vue'
import myButton from '@/components/myButton/index.vue'
import { getHomeArticle, getBanner, getInvestStrategy , getNews} from '@/api/home'
export default {
  name: 'index',
  data() {
    return {
      banners: [{ url: '', link: '' }],
      title_1: '公司介绍',
      title_2: '投资策略',
      title_3: '新闻中心',
      button_1: '查看详情',
      button_2: '查看更多',
      investmentList: '',
      article: {
        title: '',
        content: '',
        bg: '',
        digest: '',
      },
      newsList: '',
    }
  },
  components: {
    mySwiper: mySwiper,
    myTitle: myTitle,
    myButton: myButton,
    myBanner: myBanner,
  },
  created() {
    getBanner({ pageNo: 1, pageSize: 5 }).then((res) => {
      //console.log(res)
      this.banners = res.list.map((item) => ({
        url: item.article.coverList[1].oldUrl,
        link: item.article.outUrl,
      }))
      
      //console.log(this.banners)
    })
    getHomeArticle().then((res) => {
      this.article = res.article
      this.article.bg = res.article.coverList[1].oldUrl
      //console.log(res)
    })
    getInvestStrategy({ pageNo: 1, pageSize: 4 }).then((res) => {
      this.investmentList = res.list.map((item) => ({
        id: item.id,
        title: item.article.title,
        digest: item.article.digest,
        time: this.$dayjs(item.publishTime).format('YYYY-MM-DD'),
        // image: item.article.coverList[1].oldUrl,
      }))
      //console.log(res)
    })
    //新闻中心
    getNews({ pageNo: 1, pageSize: 4 }).then((res) => {
      this.newsList = res.list.map((item) => ({
        id: item.id,
        title: item.article.title,
        digest: item.article.digest,
        time: this.$dayjs(item.publishTime).format('YYYY-MM-DD'),
        // image: item.article.coverList[1].oldUrl,
      }))
      //console.log(res)
    })
  },
  //接口拿到数据，页面更新后，锚点定位
  updated(){
    let anchor = this.$route.query.anchor
    if( !!anchor ){
      this.$anchorjs(anchor)
    }
  },
  methods: {
    goToCompany() {
      this.$router.push({ path: 'company' })
      this.$store.commit('changePage', 1)
    },
    goToInvest() {
      this.$router.push({ path: 'invest' })
      this.$store.commit('changePage', 2)
    },
    goToNewsCenter() {
      this.$router.push({ path: 'company', query:{ anchor: 3 }})
      this.$store.commit('changePage', 1)
    },
    goToLink(link) {
      window.location.href = link
    },
    goToNews(id) {
      this.$router.push({ path: 'news', query: { id: id } })
      // $store.commit('changePage', 2)
    },
  },
}
</script>

<style lang="scss">
.index {
  .title_s1 {
    margin: 60px auto 90px auto;
  }
  .news {
    width: 920px;
    overflow: hidden;
    margin: 0 auto 110px auto;
    img {
      width: 300px;
      height: 225px;
    }
    .news_right {
      width: 580px;
      p {
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        color: #303a51;
      }
      .content {
        height: 100px;
        font-size: 14px;
        color: #575c6d;
        line-height: 20px;
      }
      .news_button {
        margin-top: 30px;
      }
    }
  }
  .investment{
    background: url('../../assets/img/index_bg.png') no-repeat center;
    background-size: 100% 820px;
  }
  .newsList{
    background: #fff;
    .w1200 .right .investment_list .left{
      border-color: #EDEEEF !important;
    }
  }
  .investment, .newsList {
    height: 820px;
    
    overflow: hidden;
    .title_s2 {
      margin: 50px auto 80px auto;
    }
    .w1200 {
      overflow: hidden;
      .left {
        width: 130px;
        height: 516px;
      }
      .right {
        width: 1040px;
        .investment_list {
          overflow: hidden;
          .left {
            width: 114px;
            height: 114px;
            background: #f8f7f7;
            border: 6px solid #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            align-content: center;
            p {
              width: 100%;
              font-size: 16px;
              color: #8d909b;
            }
            span {
              width: 100%;
              font-size: 28px;
              color: #575c6d;
              margin-top: 5px;
            }
          }
          .right {
            width: 906px;
            .investment_title {
              font-size: 18px;
              color: #303a51;
              width: 830px;
              height: 25px;
              line-height: 25px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin: 20px 0;
            }
            div {
              position: relative;
              height: 20px;
              p {
                font-size: 14px;
                color: #8d909b;
                width: 830px;
                height: 20px;
                line-height: 20px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              span {
                position: absolute;
                right: 0;
                top: 0;
                font-size: 14px;
                color: #2074cc;
                background: url('../../assets/img/arrows_double.png') no-repeat
                  right center;
                background-size: 11px;
                padding-right: 20px;
              }
            }
          }
        }
        .investment_list:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
    .investment_button {
      margin: 30px 0 0 295px;
    }
  }
}
</style>
