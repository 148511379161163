import request from '@/utils/request'

export function getHomeArticle(data = {}) {
  return request({
    url: '/home/homeArticle',
    method: 'post',
    data
  })
}

export function getBanner(data = {}) {
  return request({
    url: '/home/banner',
    method: 'post',
    data
  })
}

export function getInvestStrategy(data = {}) {
  return request({
    url: '/home/investStrategy',
    method: 'post',
    data
  })
}

export function getNews(data = {}) {
  return request({
    url: '/newsCenter/newsCenterList',
    method: 'post',
    data
  })
}
