<!--
 * @Author: diaowangtao
 * @Date: 2020-08-18 14:07:29
 * @Description: banner
-->

<template>
  <el-carousel trigger="click" height="400px" indicator-position="none" :banners="banners">
    <el-carousel-item v-for="(item,index) in banners" :key="index">
      <!-- <img :src="item.url" @click="goToLink(item.link)" /> -->
      <div
        class="banner"
        :style="{backgroundImage:'url('+item.url+')'}"
        @click="goToLink(item.link)"
      ></div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: 'myBanner',
  components: {},
  props: {
    banners: '',
  },
  data() {
    return {}
  },
  methods: {
    goToLink(link) {
      this.$emit('goToLink', link)
    },
  },
  mounted() {},
}
</script>

<style lang='scss'>
.el-carousel__item img {
  display: block;
  width: 100%;
  height: 400px;
}
.banner {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>